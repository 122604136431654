import TopImage from "../../assets/images/food.webp";
import RightImage from "../../assets/images/food-2.jpg";

export const data = {
  topImage: { src: TopImage, alt: "Application of crocin in Food, Crocin of saffron ,Crocin manufacture, crocin producer, crocin supplier, Saffron extract better mood, High purity saffron extract" },
  rightImage: {
    src: RightImage,
    alt: "Application of crocin in Food, Crocin of saffron ,Crocin manufacture, crocin producer, crocin supplier, Saffron extract better mood, High purity saffron extract",
  },
  seo:"Application of crocin in Food, Crocin of saffron ,Crocin manufacture, crocin producer, crocin supplier, Saffron extract better mood, High purity saffron extract",
  keyword:["Application of Crocin in Food","Functional food","pure saffron extract","saffranal","Herbal saffron extract", "Crocin of Saffron","Crocin manufacture", "Crocin producer", "Crocin supplier", "Crocin","Saffron extract better mood", "High purity saffron extract"],
  cardInfo: {
    title: "Application of crocin in Food",
    Subtitle: `Saffron extract better mood,Functional food, Crocin of saffron, Analysis of food crocin`,
    text: [
      {
        id: 1,
        value: `Natural dye are not only having dyeing property but also having
        the wide range of medicinal properties.Nowdays, fortunately ,
        there is increasing awareness among people towards natural
        dyes and also functional food and drink. Due to not toxic
        properties , less side effect , more medicinal value,natural dye
        are use in food and drink .`,
      },
      {
        id: 2,
        value: `Crocin of saffron the most valuable products used in functional
        products as coloring and flavoring agent. This properties are
        basically related to food grade crocin thate have
        crocin,picrocrocin and safranal..`,
      },
    ],
  },
  tableSubtitle: "Analysis of food crocin",
  tables: [
    {
      id: 1,
      thead: [
        {
          id: 1,
          tr: {
            id: 11,
            th: [
              {
                id: 111,
                text: "Total saffron extract(TSE)",
                colSpan: 2,
                textStyle: "text-center",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Technical Data",
          td: [
            {
              id: 1,
              text: `Crocin (CAS 42553-65-1)
              Alternate Names: Bis(6-O-beta-D-glucopyranosyl-beta-D-glucopyranosyl) 8,8'-diapo-psi,psicarotenedioate; Crocetin digentiobiose ester
              CAS Number: 42553-65-1
              Molecular Weight: 976.96
              Molecular Formula: C44H64O24
              Physical State : Solid
              Storage : Store at 4° C
              Melting Point : 186° C
              Boiling Point : ~1169.0° C at 760 mmHg (Predicted)
              Density : ~1.5 g/cm3`,
              style: "pre-line",
            },
          ],
        },
        {
          id: 2,
          th: "DEFINITION",
          td: [
            {
              id: 2,
              text: `Coloring principle of saffron (dried stigmas of flowers of Crocus sativus L).`,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      thead: [
        {
          id: 1,
          tr: {
            id: 11,
            th: [
              {
                id: 111,
                text: "Physicochemical analysis",
                colSpan: 3,
                textStyle: "text-center",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Physical state",
          td: [
            {
              id: 1,
              text: `Sticky solid`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
        {
          id: 2,
          th: "source",
          td: [
            {
              id: 1,
              text: `Saffron stigmas`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
        {
          id: 3,
          th: "solubility",
          td: [
            {
              id: 1,
              text: `easily soluble in water and methanol`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
        {
          id: 4,
          th: "Description",
          td: [
            {
              id: 1,
              text: `Deep red color, sticky solid. Orang solution in
                water`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
        {
          id: 5,
          th: "Spectrophotometry(UV-Vis)",
          td: [
            {
              id: 1,
              text: `Maximum absorption in water at (257, 330, 440)
                nm using a 1 cm quartz cell.`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      thead: [
        {
          id: 3,
          tr: {
            id: 33,
            th: [
              {
                id: 333,
                text: "E 1%",
                colSpan: 1,
                textStyle: "text-left",
              },
              {
                id: 334,
                text: "Result",
                colSpan: 1,
                textStyle: "text-left",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Picrocrocin(E1% at 257 nm)",
          td: [
            {
              id: 1,
              text: `220.5`,
            },
          ],
        },
        {
          id: 2,
          th: "Safranal(E 1% at 330 nm)",
          td: [
            {
              id: 2,
              text: `100.25`,
            },
          ],
        },
        {
          id: 3,
          th: "Crocin(E 1% at 440 nm)",
          td: [
            {
              id: 3,
              text: `408.9`,
            },
          ],
        },
        {
          id: 4,
          th: "Moisture and volatile matter content",
          td: [
            {
              id: 4,
              text: `9.4%`,
            },
          ],
        },
        {
          id: 5,
          th: "Crocin percentage",
          td: [
            {
              id: 5,
              text: `45%`,
            },
          ],
        },
        {
          id: 6,
          th: "Total ash",
          td: [
            {
              id: 6,
              text: `NA`,
            },
          ],
        },
      ],
    },
    {
      id: 4,
      thead: [
        {
          id: 4,
          tr: {
            id: 44,
            th: [
              {
                id: 44,
                text: "Microbial analysis",
                colSpan: 3,
                textStyle: "text-center",
              },
            ],
          },
        },
        {
          id: 41,
          tr: {
            id: 442,
            th: [
              {
                id: 4421,
                text: "",
                colSpan: 1,
                textStyle: "text-center",
              },
              {
                id: 4422,
                text: "Acceptable range",
                colSpan: 1,
                textStyle: "text-left",
              },
              {
                id: 4423,
                text: "Result",
                colSpan: 1,
                textStyle: "text-left",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Total yeasts and molds count",
          td: [
            {
              id: 1,
              text: `≤ 100 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 2,
          th: "Total aerobic microbial count",
          td: [
            {
              id: 1,
              text: `≤ 10000 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
            id: 3,
            th: "Bile-tolerant gram negative bacteria",
            td: [
              {
                id: 1,
                text: `≤ 100 cfu/g`,
              },
              {
                id: 2,
                text: `0 cfu/g`,
              },
            ],
          },
          {
            id: 4,
            th: "Total coliforms count",
            td: [
              {
                id: 1,
                text: `0 cfu/g`,
              },
              {
                id: 2,
                text: `0 cfu/g`,
              },
            ],
          },
          {
            id: 5,
            th: "E. coli",
            td: [
              {
                id: 1,
                text: `0 cfu/g`,
              },
              {
                id: 2,
                text: `0 cfu/g`,
              },
            ],
          },
          {
            id: 6,
            th: "Salmonella species",
            td: [
              {
                id: 1,
                text: `0 cfu/g`,
              },
              {
                id: 2,
                text: `0 cfu/g`,
              },
            ],
          },
          {
            id: 7,
            th: "Candida albicans",
            td: [
              {
                id: 1,
                text: `0 cfu/g`,
              },
              {
                id: 2,
                text: `0 cfu/g`,
              },
            ],
          },
          {
            id: 8,
            th: "Pseudomonas aeruginosa",
            td: [
              {
                id: 1,
                text: `0 cfu/g`,
              },
              {
                id: 2,
                text: `0 cfu/g`,
              },
            ],
          },
          {
            id: 9,
            th: "Staphylococcus aureus",
            td: [
              {
                id: 1,
                text: `0 cfu/g`,
              },
              {
                id: 2,
                text: `0 cfu/g`,
              },
            ],
          },
      ],
    },
  ],
};
