import { Link } from "react-router-dom";
import { Container, Row, Col, CardBody, CardText, CardTitle } from "reactstrap";
import { data } from "./data";
import Slideshow from "../../component/slideshow";
import CardWrapper from "../../component/cardWrapper";

const Home = () => {
  return (
    <Container fluid>
      <Slideshow />

      <Row>
        {data.shortMenu.map(({ title, link, styleName }) => (
          <Col lg={3} md={3} sm={12} className={styleName} key={title}>
            <Link to={link} className="ab">
              {title}{" "}
            </Link>
          </Col>
        ))}
      </Row>
      <Container>
        <Row className="justify-content-center">
          <Col lg="9" md="9" sm="12" className="">
            <CardWrapper
              key={1}
              title={data.aboutUs.cardInfo.title}
              Subtitle={data.aboutUs.cardInfo.Subtitle}
              text={data.aboutUs.cardInfo.text}
            />
          </Col>
        </Row>
      </Container>
      <div className="bgfix1">
        <Row className="justify-content-center">
          <Col md={8} lg={8}>
            <div className="colorW">
              <CardWrapper
                key={2}
                title={data.whatIsCrocin.cardInfo.title}
                Subtitle={data.whatIsCrocin.cardInfo.Subtitle}
                text={data.whatIsCrocin.cardInfo.text}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Container>
        <Row className="justify-content-center">
          <Col lg="9" md="9" sm="12">
            <CardWrapper
              key={3}
              title={data.crocin.cardInfo.title}
              Subtitle={data.crocin.cardInfo.Subtitle}
              text={data.crocin.cardInfo.text}
            />
          </Col>

          <Col lg="6" md="6" sm="12">
          <CardWrapper
              key={4}
              text={data.crocin.leftCardInfo.text}
              noneDivider
            />
          </Col>
          <Col lg="3" md="3" sm="12" className="my-auto text-center">
            <img
              width="100%"
              src={data.crocin.image.src}
              alt={data.crocin.image.alt}
            />
          </Col>
        </Row>
      </Container>
      <div className="bg-dark colorW">
        <CardBody>
          <Row className="justify-content-center">
            <Col md={3} lg={3}>
              <CardTitle>{data.nanotechnology.title}</CardTitle>
            </Col>
            <Col md={6} lg={6}>
              <CardText>{data.nanotechnology.text}</CardText>
            </Col>
          </Row>
        </CardBody>
      </div>
      <div className="bgfix2"></div>
    </Container>
  );
};
export default Home;
