import TopImage from "../../assets/images/about-us.jpg";
import RightImage from "../../assets/images/about-us2.jpg";

export const data = {
  topImage: { src: TopImage, alt: "Saffron processing refinery_Saffron extract_Crocin manufacture_crocin producer_crocin supplier_Pure crocin" },
  rightImage: { src: RightImage, alt: "Saffron processing refinery_Saffron extract_Crocin manufacture_crocin producer_crocin supplier_Pure crocin" },
  seo: 'Manufacturer of Active ingredients from pure saffron and other Native plants from iran such as Saffron processing refinery, Saffron extract, Crocin manufacture, Crocin producer, Crocin supplier, Pure crocin,Saffron innovation center, Crocin supplement, Saffron extract supplement, Active extract, Herbal extract, Saffron extract improve depression, High purity saffron extract, Pure saffron extract, Saffron extract better mood',
  keyword: ["Saffron processing refinery", "Saffron extract", "Crocin manufacture", "Crocin producer", "Crocin supplier", "Pure crocin", "Saffron innovation center", "Crocin supplement", "Saffron extract supplement", "Active extract", "Herbal extract", "Saffron extract improve depression", "High purity saffron extract", "Pure saffron extract", "Saffron extract better mood"],
  cardInfo: {
    title: "About us",
    Subtitle: `the Saffron and herbal extract manufacturer and also research, development, production and formulation of product
    with crocin `,
    text: [
      {
        id: 1,
        value: `The World trend of rising demand for natural product. The tendency to identification of numerous
        uses of saffron in different industries such as Pharmaceutical, food and cosmetic is increasing in the
        world.`,
      },
      {
        id: 2,
        value: `Since Iran is known as a source of high-quality saffron and the biggest producer of this crop in the
        world; our company try to extract all active ingredient of saffron in industrial scale.`,
      },
      {
        id: 3,
        value: `Agile Smart Health Technology Company is the Saffron and herbal extract manufacturer also ready to
        collaboration with other company in research, development, production and formulation of product
        with crocin based such as antidepressant coffee, beverage and other products with nanoencapsulation
        method.`,
      },
    ],
  },
  InfoLeft: {
    text: [
      {
        id: 4,
        value: `Agile Smart Health Technology Company,
          located in the Khorasan Razavi Science and
          Technology Park, and CEO of this company
          Dr.Parisa Mardani (also CEO of herbal medicine
          union of Khorasan provience) ,That firs
          innovator of the first processing and innovation
          center for saffron product in Iran , is ready to
          provide the following services to food and
          herbal medicine companies.`,
      },
      {
        id: 2,
        value: `Our headquarter is located in Iran and our
          international sales office is located in Germany`,
      },
    ],
  },
};
