import { Container, Row, Col } from "reactstrap";
import CardWrapper from "../../component/cardWrapper";
import TableWrapper from "../../component/tableWrapper";
import { data } from "./data";

const ContactUs = () => {
  return (
    <div>
      <Container fluid>
        <img src={data.topImage.src} alt={data.topImage.alt} width="100%" />
      </Container>
      <Container>
        <Row>
          <Col>
          <h3 className="d-none">{data.seo}</h3>
            <CardWrapper
              Subtitle={data.cardInfo.Subtitle}
              title={data.cardInfo.title}
              text={data.cardInfo.text}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {data.tables.map(({ thead, tbody, id }) => (
              <TableWrapper key={id} thead={thead} tbody={tbody} />
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ContactUs;
