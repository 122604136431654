import { Table } from "reactstrap";

const TableWrapper = ({ thead, tbody }) => {
  return (
    <div className="mb-5">
      <Table responsive>
        <thead>
          {thead.map(({ id, tr }) => (
            <tr key={id}>
              {tr.th.map(({ colSpan, id, text, textStyle }) => (
                <th colSpan={colSpan} key={id} className={textStyle}>
                  {text}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {tbody.map(({ th, td, id }) => (
            <tr key={id}>
              <th scope="row">{th}</th>
              {td.map(({ id, text, style, type }) => (
                <td key={id}>
                  {type === `link` && (
                    <a className="text-info" href={text}>
                      {text}
                    </a>
                  )}
                  {type === `mail` && (
                    <a className="text-info" href={`mailto:${text}`}>
                      {text}
                    </a>
                  )}
                  {!type && <div className={style}>{text}</div>}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableWrapper;
