import { data as aboutUsData } from "../aboutUs/data";
import CrocinImage from "../../assets/images/home-crocin.webp";
export const data = {
  shortMenu: [
    {
      title: "Crocin for Pharmaceutical (pure crocin)",
      link: "/pharmaceutical",
      styleName: "b1",
    },
    {
      title: "Crocin for Food",
      link: "/food",
      styleName: "b2",
    },
    {
      title: "Crocin for Cosmetic",
      link: "/cosmetic",
      styleName: "b3",
    },
    {
      title: "Herbal medicine of Iran",
      link: "/native-herbal-medicinec",
      styleName: "b4",
    },
  ],
  aboutUs: {
    cardInfo: {
      title: aboutUsData.cardInfo.title,
      subtitle: aboutUsData.cardInfo.subtitle,
      text: [...aboutUsData.cardInfo.text, aboutUsData.InfoLeft.text[0]],
    },
  },
  whatIsCrocin: {
    cardInfo: {
      title: "What is Crocin?",
      subtitle: "",
      text: [
        {
          id: 1,
          value: `Crocin is a carotenoid chemical compound that is found in the flowers Crocus Sativus (scientific name
                of saffron) and Gardenia Jasminoides.`,
        },
        {
          id: 2,
          value: `Crocin is chemical compound primarily responsible for the color of saffron.`,
        },
      ],
    },
  },
  crocin: {
    cardInfo: {
      title: "Crocin",
      subtitle: "",
      text: [
        {
          id: 1,
          value: `Chemically, Crocin is the diester formed from the disaccharide gentiobiose and the Dicarboxylic Acid
            Crocetin. When isolated as a pure chemical compound, it has a deep violet color and forms crystals
            with a melting point of 186 . when dissolved in water, it forms an orange solution.`,
        },
      ],
    },
    leftCardInfo: {
      title: "",
      subtitle: "",
      text: [
        {
          id: 2,
          value: `Some advantages of Crocin overSaffron:`,
        },
        {
          id: 3,
          value: `1. pure extract(up to 99.93%)`,
        },
        {
          id: 4,
          value: `2. Over 18 months shelf life`,
        },
        {
          id: 5,
          value: `3. Low volume, easy forwarding`,
        },
        {
          id: 6,
          value: `4. Better solubility, consistent quality`,
        },
        {
          id: 7,
          value: `5. Easy storage condition (2-8 C temperature)`,
        },
        {
          id: 8,
          value: `6. No additives, almost 0% toxic substances`,
        },
        {
          id: 9,
          value: `7. Almost 30 times richer than Saffron herb color`,
        },
        {
          id: 10,
          value: `8.Antibacterial properties due to the absence of cellulosic tissue`,
        },
      ],
    },
    image: {
      src: CrocinImage,
      alt: "Crocin",
    },
  },
  nanotechnology: {
    title: "Nanotechnology in herb processing",
    text: `Consulting in the field of formulation of healthy and functional products based on plant
        (nanocapsulation and drug delivery technique in form of niosomic and liposomal) , saffron value chain
        and processing and Supply of herbal medicine grow in Iran.`,
  },
};
