import { Link } from "react-router-dom";
import { List, ListInlineItem } from "reactstrap";

const RelatedWord = ({ keyword, currentPage }) => (
    <List>
        <ListInlineItem><h5>Keyword:</h5></ListInlineItem>
        {keyword.map((item) => (<ListInlineItem key={item}>
            <Link to={currentPage} className="btn btn-info"> <h6 >{item}</h6></Link>
        </ListInlineItem>))}
    </List>
);
export default RelatedWord;
