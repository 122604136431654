import { Container, Row, Col } from "reactstrap";
import CardWrapper from "../../component/cardWrapper";
import RelatedWord from "../../component/relatedKeword";
import { data } from "./data";

const NativeHerbalMedicine = () => {
  return (
    <div>
      <Container fluid>
      <img src={data.topImage.src} alt={data.topImage.alt} width="100%" />

      </Container>
      <Container>
        <Row>
          <Col>
            <CardWrapper
              Subtitle={data.cardInfo.Subtitle}
              title={data.cardInfo.title}
              text={data.cardInfo.text}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center mb-5">
            <img src={data.image.src} alt={data.image.alt} width="100%" />
            <RelatedWord currentPage={"/native-herbal-medicine"} keyword={data.keyword} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default NativeHerbalMedicine;
