import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./style.css";
import Slide1 from "../../assets/images/slide1.jpg";
import Slide2 from "../../assets/images/slide2.jpg";
import Slide3 from "../../assets/images/slide4.jpg";
import Slide4 from "../../assets/images/slide5.jpg";

const Slideshow = () => {
  const images = [Slide1, Slide2, Slide3, Slide4];

  return (
    <Slide slidesToScroll={1} slidesToShow={1} indicators={false}>
      <div className="each-slide-effect">
         <div style={{ backgroundImage: `url(${images[0]})` }}>
          <h1>Agile Smart Health Technology</h1>
          <h2>Saffron processing refinery</h2>
        </div>
      </div>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${images[1]})` }}>
          <h1>Crocin of saffron</h1>
          <h2>Supplement saffron extract</h2>
        </div>
      </div>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${images[2]})` }}>
          <h1>Pure saffron extract</h1>
          <h2>Anthocyanin of saffron </h2>
        </div>
      </div>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${images[3]})` }}>
           <h1>Herbal saffron extract</h1>
           <h2>Functional food</h2> 
        </div>
      </div>
    </Slide>
  );
};
export default Slideshow;
