import React from "react";
import { Link } from "react-router-dom";

import { data } from "./data";

function Footer(props) {
  return (
    <div className="footer pt-5 pb-1">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 text-right">
          </div>
          <div className="col-lg-12 col-md-12">
            <h5>Quick Menu</h5>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <ul className="list-unstyled ">
                  {data.menu1.map(({ link, name }) => (
                    <li key={name}>
                      <Link to={link}>{name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6 col-md-6">
                <ul className="list-unstyled ">
                  {data.menu2.map(({ link, name }) => (
                    <li key={name}>
                      <Link to={link}>{name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center dir-ltr ">
          <div className="col-auto col-12">
            <p className=" Cp">
              © Copyright 2020 Agile Smart Health Technology - Supplement Saffron Extract - Crocin Manufacture - Saffron Processing Refinery | Product by{" "}
              <a
                href="https://www.linkedin.com/in/elham-baradaran-heravi/"
                target="blank"
              >
                Elham B-Heravi
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
