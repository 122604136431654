import { Container, Row, Col } from "reactstrap";
import CardWrapper from "../../component/cardWrapper";
import RelatedWord from "../../component/relatedKeword";
import { data } from "./data";

const ActiveIngredient = () => {
  return (
    <div>
      <Container fluid>
        <img src={data.topImage.src} alt={data.topImage.alt} width="100%" />
        <h3 className="d-none">{data.seo}</h3>
      </Container>
      <Container>
        <Row>
          <Col md={8}>
            <CardWrapper
              Subtitle={data.cardInfo.Subtitle}
              title={data.cardInfo.title}
              text={data.cardInfo.text}
            />
          </Col>
          <Col md={4} className="my-auto text-center">
            <img src={data.rightImage1.src} alt={data.rightImage1.alt} />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <CardWrapper
              Subtitle={data.cardInfo2.Subtitle}
              title={data.cardInfo2.title}
              text={data.cardInfo2.text}
            />
          </Col>
          <Col md={4} className="my-auto text-center">
            <img src={data.rightImage2.src} alt={data.rightImage2.alt} />
          </Col>
        </Row>
        <Row>
          <Col>
          <RelatedWord  currentPage={"/active"} keyword={data.keyword} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ActiveIngredient;
