import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import Pharmaceutical from "./pages/pharmaceutical";
import Food from "./pages/food";
import Cosmetic from "./pages/cosmetic";
import ActiveIngredient from "./pages/activeIngredient";
import NativeHerbalMedicine from "./pages/nativeHerbalMedicine";
import ContactUs from "./pages/contactUs";
import Header from "./component/header";
import Footer from "./component/footer";
import { useEffect, useState } from "react";


function App() {
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const windowsPosition = window.scrollY < 100;
      if (windowsPosition !== isTop) {
        setIsTop(windowsPosition);
      }
    });
  });
  return (
    <div className="App">
      <BrowserRouter>
      <Header isTop={isTop} />
        <Routes>
          <Route element={<Home />} path="/"></Route>
          <Route element={<AboutUs />} path="/about-us"></Route>
          <Route element={<Pharmaceutical />} path="/pharmaceutical"></Route>
          <Route element={<Food />} path="/food"></Route>
          <Route element={<Cosmetic />} path="/cosmetic"></Route>
          <Route element={<ActiveIngredient />} path="/active"></Route>
          <Route
            element={<NativeHerbalMedicine />}
            path="/native-herbal-medicine"
          ></Route>
          <Route element={<ContactUs />} path="/contact-us"></Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
