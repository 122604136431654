import TopImage from "../../assets/images/cosmetic.webp";
import RightImage from "../../assets/images/cosmetic-2.jpg";

export const data = {
  topImage: { src: TopImage, alt: "Application of crocin in Cosmetic,Saffron Extract, Natural cosmetic, Crocin manufacture, crocin producer, crocin supplier, Pure crocin, Crocin, Crocin supplement, Active extract" },
  rightImage: {
    src: RightImage,
    alt: "Application of crocin in Cosmetic, Natural cosmetic, Saffron Extract, Crocin manufacture, crocin producer, crocin supplier, Pure crocin, Crocin, Crocin supplement, Active extract",
  },
  seo: "Application of crocin in Cosmetic,Natural cosmetic, Analysis of cosmetic crocin Microbial analysis,Analysis of cosmetic crocin Physicochemical analysis, Crocin supplement, Active extract ",
  keyword: [ "Natural cosmetic","Crocin","Crocin manufacture", "Crocin producer", "Crocin supplier", "Pure crocin", "Analysis of cosmetic crocin","Microbial analysis","Physicochemical analysis", "Saffron Extract","Crocin supplement","Active extract" ],
 
  cardInfo: {
    title: "Application of crocin in Cosmetic",
    Subtitle: `Natural cosmetic, Crocin manufacture, crocin producer`,
    text: [
      {
        id: 1,
        value: `The tincture of crocin grade cosmetic used to remove pimples and
        sootherashes in persian traditional medicine mostly by Avicenna
        wordly physician who live thousand years ago.`,
      },
      {
        id: 2,
        value: `As one of the Persian traditional medication ,saffron has been
        used in order to shine, lighten the skin and reduce dark pigment
        , dark circle under the eyes, acne, and pimple.`,
      },
      {
        id: 3,
        value: `It is suspected that crocin inhibits active compound DNA
        damage, or acts as an activator for DNA excision repair enzyme.
        Furthermore, crocin of saffron has anti-solar effect and can absorb
        UV rays of sun and used in formulation of lotions or creams.`,
      },
    ],
  },
  tableSubtitle: "Analysis of cosmetic crocin",
  tables: [
    {
      id: 1,
      thead: [
        {
          id: 1,
          tr: {
            id: 11,
            th: [
              {
                id: 111,
                text: "Saffron Extract(SE)",
                colSpan: 2,
                textStyle: "text-center",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Technical Data",
          td: [
            {
              id: 1,
              text: `Crocin (CAS 42553-65-1)
              Alternate Names: Bis(6-O-beta-D-glucopyranosyl-beta-D-glucopyranosyl) 8,8'-diapo-psi,psicarotenedioate; Crocetin digentiobiose ester
              CAS Number: 42553-65-1
              Molecular Weight: 976.96
              Molecular Formula: C44H64O24
              Physical State : Solid
              Storage : Store at 4° C
              Melting Point : 186° C
              Boiling Point : ~1169.0° C at 760 mmHg (Predicted)
              Density : ~1.5 g/cm3`,
              style: 'pre-line'
            },
          ],
        },
        {
          id: 2,
          th: "DEFINITION",
          td: [
            {
              id: 2,
              text: `Coloring principle of saffron (dried stigmas of flowers of Crocus sativus L).`,
            },
          ],
        },

      ],
    },
    {
      id: 2,
      thead: [
        {
          id: 2,
          tr: {
            id: 22,
            th: [
              {
                id: 222,
                text: "Physicochemical analysis",
                colSpan: 3,
                textStyle: "text-center",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Physical state",
          td: [
            {
              id: 1,
              text: `Sticky solid`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
        {
          id: 2,
          th: "source",
          td: [
            {
              id: 1,
              text: `Saffron stigmas`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
        {
          id: 3,
          th: "solubility",
          td: [
            {
              id: 1,
              text: `easily soluble in water and methanol`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
        {
          id: 4,
          th: "Description",
          td: [
            {
              id: 1,
              text: `Deep red color, sticky solid. Orang solution in water`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
        {
          id: 5,
          th: "Spectrophotometry(UV-Vis)",
          td: [
            {
              id: 1,
              text: `Maximum absorption in water at (257, 330, 440) nm
              using a 1 cm quartz cell.`,
            },
            {
              id: 2,
              text: `conform`,
            },
          ],
        },
      ],
    }
    , {
      id: 3,
      thead: [
        {
          id: 3,
          tr: {
            id: 33,
            th: [
              {
                id: 333,
                text: "E 1%",
                colSpan: 1,
                textStyle: "text-left",
              },
              {
                id: 334,
                text: "Result",
                colSpan: 1,
                textStyle: "text-left",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Picrocrocin(E1% at 257 nm)",
          td: [
            {
              id: 1,
              text: `134.6`,
            },
          ],
        },
        {
          id: 2,
          th: "Safranal(E 1% at 330 nm)",
          td: [
            {
              id: 1,
              text: `75`,
            },
          ],
        },
        {
          id: 3,
          th: "Crocin(E 1% at 440 nm)",
          td: [
            {
              id: 1,
              text: `156.4`,
            },
          ],
        },
        {
          id: 4,
          th: "Crocin percentage",
          td: [
            {
              id: 1,
              text: `25.3%(w/w)`,
            },
          ],
        },
        {
          id: 5,
          th: "Moisture and volatile matter content",
          td: [
            {
              id: 1,
              text: `10.8`,
            },
          ],
        },
        {
          id: 6,
          th: "Total ash",
          td: [
            {
              id: 1,
              text: `NA`,
            },
          ],
        },
      ],
    },
    {
      id: 4,
      thead: [
        {
          id: 1,
          tr: {
            id: 11,
            th: [
              {
                id: 111,
                text: "Microbial analysis",
                colSpan: 3,
                textStyle: "text-center",
              },
            ],
          },
        },
        {
          id: 2,
          tr: {
            id: 22,
            th: [
              {
                id: 222,
                text: "",
                colSpan: 1,
                textStyle: "text-left",
              },
              {
                id: 223,
                text: "Acceptable range",
                colSpan: 1,
                textStyle: "text-left",
              },
              {
                id: 224,
                text: "Result",
                colSpan: 1,
                textStyle: "text-center",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Total yeasts and molds count",
          td: [
            {
              id: 1,
              text: `≤ 100 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 2,
          th: "Total aerobic microbial count",
          td: [
            {
              id: 1,
              text: `≤ 10000 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 3,
          th: "Bile-tolerant gram negative bacteria",
          td: [
            {
              id: 1,
              text: `≤ 100 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 4,
          th: "Total coliforms count",
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 5,
          th: "E. coli",
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 6,
          th: "Salmonella species",
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 7,
          th: "Candida albicans",
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 8,
          th: "Pseudomonas aeruginosa",
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 9,
          th: "Staphylococcus aureus",
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
      ],
    },
  ],
};
