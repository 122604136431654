import TopImage from '../../assets/images/contact-us.webp';

export const data = {
  topImage: { src: TopImage, alt: "Agile Smart Health Technology, Saffron processing refinery, Saffron extract, Crocin manufacture, crocin producer, crocin supplier, Pure crocinSaffron innovation center, Crocin supplement, Saffron extract supplement, Active extract, Herbal extract, Saffron extract improve depression, High purity saffron extract, Pure saffron extract, Saffron extract better mood" },
  seo: "Agile Smart Health Technology, Saffron processing refinery, Saffron extract, Crocin manufacture, crocin producer, crocin supplier, Pure crocinSaffron innovation center, Crocin supplement, Saffron extract supplement, Active extract, Herbal extract, Saffron extract improve depression, High purity saffron extract, Pure saffron extract, Saffron extract better mood",
  cardInfo: {
    title: "Contact us",
    Subtitle: `Agile Smart Health Technology, Saffron extract, production and formulation of product with crocin based`,
    text: [
      {
        id: 1,
        value: ``,
      },
    ],
  },
  tables: [
    {
      id: 1,
      thead: [
        {
          id: 1,
          tr: {
            id: 11,
            th: [
              {
                id: 111,
                text: "GERMANY",
                colSpan: 2,
                textStyle: "text-center"
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Address:",
          td: [
            {
              id: 1,
              text: `C/O : Schlossstrasse 48a , D-12165 Berlin`,
            },
          ],
        },
        {
          id: 2,
          th: "Phone:",
          td: [
            {
              id: 2,
              text: `(+49) 307978330-133`,
            },
          ],
        },
        {
          id: 3,
          th: "Mobile:",
          td: [
            {
              id: 3,
              text: `(+49) 15154690360`,
            },
          ],
        },
        {
          id: 4,
          th: "‌site:",
          td: [
            {
              id: 4,
              text: `https://ahealthyst.com/`,
              type: `link`
            }
          ],
        },
        {
          id: 41,
          th: "‌",
          td: [
            {
              id: 4,
              text: `https://www.cet-co.eu/`,
              type: `link`
            }
          ],
        },
        {
          id: 5,
          th: "Mail:",
          td: [
            {
              id: 5,
              text: `info@ahealthyst.com`,
              type: `mail`
            }
          ],
        },
        {
          id: 6,
          th: "",
          td: [
            {
              id: 51,
              text: `elahi@ahealthyst.com`,
              type: `mail`
            },
          ],
        },
        {
          id: 7,
          th: "wlw.de",
          td: [
            {
              id: 51,
              text: `https://www.wlw.de/de/firma/cet-co-gmbh-20014812`,
              type: `link`
            },
          ],
        },
        {
          id: 8,
          th: "Pure corcin at wlw.de",
          td: [
            {
              id: 51,
              text: `https://www.wlw.de/de/suche?q=pure+crocin`,
              type: `link`
            },
          ],
        },
        {
          id: 9,
          th: "Saffron extract at wlw.de",
          td: [
            {
              id: 51,
              text: `https://www.wlw.de/de/suche?q=saffron+extract`,
              type: `link`
            },
          ],
        },
      ],
    },
    {
      id: 2,
      thead: [
        {
          id: 2,
          tr: {
            id: 22,
            th: [
              {
                id: 111,
                text: "IRAN",
                colSpan: 2,
                textStyle: "text-center"
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Address:",
          td: [
            {
              id: 1,
              text: `Iran, Mashhad, Khorasan science and technology park , unit 423`,
            },
          ],
        },
        {
          id: 2,
          th: "Phone:",
          td: [
            {
              id: 2,
              text: `(+98)35421115`,
            },
          ],
        },
        {
          id: 3,
          th: "Mobile:",
          td: [
            {
              id: 3,
              text: `(+98)9151171865`,
            },
          ],
        },
        {
          id: 4,
          th: "‌Site:",
          td: [
            {
              id: 4,
              text: `https://www.fa.ahealthyst.com`,
              type: `link`
            },
          ],
        },
        {
          id: 5,
          th: "Mail:",
          td: [
            {
              id: 5,
              text: `parisamardani@ahealthyst.com`,
              type: `mail`
            },
          ],
        },
        {
          id: 6,
          th: "Instagram:",
          td: [
            {
              id: 5,
              text: `https://www.instagram.com/ahealthyst`,
              type: `link`
            },
          ],
        },
      ],
    }]
};
