import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
} from "reactstrap";
import { data } from "./data";

const args = {
  color: "dark",
  light: false,
  dark: true,
  expand: "md",
  container: "fluid",
  fixed: "top",
  id: "header",
};

function Header({ isTop }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const uptoTop = () => {
    setIsOpen(false);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  return (
    <div>
      <Navbar {...args} className={isTop ? "header" : "headerDark"}>
        <NavbarBrand href="/" className="m-2">
          <img src={data.logo.src} alt={data.logo.alt} />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            {data.menu.map(({ name, link }) => (
              <NavItem key={name}>
                <NavLink
                  onClick={() => uptoTop()}
                  className="nav-link"
                  to={link}
                >
                  {name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <NavbarText>
            <a className="m-3" href="http://fa.ahealthyst.com/">
              FA
            </a>
          </NavbarText>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
