import Image from "../../assets/images/NativeHerbalMedicineOfIran.jpg";
import TopImage from "../../assets/images/herbal-top.jpg";

export const data = {
  topImage: { src: TopImage, alt: "Native Herbal Medicine Of Iran, Saffron extract improve depression, High purity saffron extract, Saffron extract better mood" },
  image: { src: Image, alt: "Native Herbal Medicine Of Iran, Saffron extract improve depression, High purity saffron extract, Saffron extract better mood" },
  keyword:["Native Herbal Medicine Of Iran", "Herbal medicine","Crocin of Saffron","Crocin manufacture", "Crocin producer", "Crocin supplier", "Crocin","High purity saffron extract","Saffron extract improve depression","Saffron extract supplement"],
  cardInfo: {
    title: "Native Herbal Medicine Of Iran",
    Subtitle: `Saffron extract improve depression, High purity saffron extract, Saffron extract better mood`,
    text: [
      {
        id: 1,
        value: `Also our company can supply all native herbal medicine of Iran ( organic form or not) with
        the best quality.`,
      },
    ],
  },
};
