import { Container, Row, Col, CardSubtitle } from "reactstrap";
import TableWrapper from "../../component/tableWrapper";
import CardWrapper from "../../component/cardWrapper";
import RelatedWord from "../../component/relatedKeword";
import { data } from "./data";
import PDF from "../../assets/MSDS OF CROCIN.pdf";

const Pharmaceutical = () => {
  return (
    <div>
      <Container fluid>
        <img
          src={data.topImage.src}
          alt={data.topImage.alt}
          height="50%"
          width="100%"
        />
        <h3 className="d-none">{data.seo}</h3>
      </Container>
      <Container>
        <Row>
          <Col md={6} className="pl-6">
            <CardWrapper
              Subtitle={data.cardInfo.Subtitle}
              text={data.cardInfo.text}
              title={data.cardInfo.title}
            />
          </Col>
          <Col md={6}>
            <img
              src={data.rightImage1.src}
              alt={data.rightImage1.alt}
              width="50%"
            />

            <img
              src={data.rightImage.src}
              alt={data.rightImage.alt}
              width="50%"
            />
          </Col>
          <Col>
            <div className="divider"></div>
            <CardSubtitle>{data.tableSubtitle}</CardSubtitle>

            {data.tables.map(({ thead, tbody, id }) => (
              <TableWrapper key={id} thead={thead} tbody={tbody} />
            ))}
            <a className="pb-5 btn btn-link" href={PDF}>
               MSDS OF CROCIN
            </a>
            <RelatedWord currentPage={"/pharmaceutical"} keyword={data.keyword} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Pharmaceutical;
