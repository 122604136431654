import topImage from "../../assets/images/pharmaceutical-top.webp";
import rightImage1 from "../../assets/images/pharmaceutical.jpg";
import RightImage from "../../assets/images/pharmaceutical2.jpg";

export const data = {
  topImage: { src: topImage, alt: "Applications of Crocin in Pharmaceutical" },
  rightImage1: {
    src: rightImage1,
    alt: "Applications of Crocin in Pharmaceutical, Analysis of Pure crocin, Crocin manufacture, crocin producer, crocin supplier, Pure crocin, Crocin supplement, High purity saffron extract",
  },
  rightImage: {
    src: RightImage,
    alt: "Applications of Crocin in Pharmaceutical, Analysis of Pure crocin, Crocin manufacture, crocin producer, crocin supplier, Pure crocin, Crocin supplement, High purity saffron extract",
  },
  seo:"Applications of Crocin in Pharmaceutical, Agile Smart Health Technology, Crocin extraction, Analysis of Pure crocin, Crocin supplementm, High purity saffron extract",
  keyword:["Crocin in Pharmaceutical", "Analysis of Pure crocin","Pure crocin", "Crocin manufacture", "crocin producer", "crocin supplier","Crocin extraction","Crocin supplement", "High purity saffron extract"],
  cardInfo: {
    title: "Applications of Crocin in Pharmaceutical",
    Subtitle: `Crocin extraction, Analysis of Pure crocin`,
    text: [
      {
        id: 1,
        value: `Herbal plants have used for folk medicine since immemorial
        times.Hippocrates who is the father of medicine, refer to 400 medicinal
        plantsand advised “let food be your medicine and let medicine be your
        food”. Most for plants are used today for medicine. It is a still acceptable
        today that prevention is more important than treatment.`,
      },
      {
        id: 2,
        value: `crocin was utilized in folk remedy against scarlet fever , colds , asthma ,
        eye and heart diseases, tumors, and cancer.`,
      },
    ],
  },
  tableSubtitle: "Analysis of Pure crocin",
  tables: [
    {
      id: 1,
      thead: [
        {
          id: 1,
          tr: {
            id: 11,
            th: [
              {
                id: 111,
                text: "Pure Crocin",
                colSpan: 2,
                textStyle: "text-center",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Technical Data",
          td: [
            {
              id: 1,
              text: `Crocin (CAS 42553-65-1)
              Alternate Names: Bis(6-O-beta-D-glucopyranosyl-beta-D-glucopyranosyl) 8,8'-diapo-psi,psicarotenedioate; Crocetin digentiobiose ester
              CAS Number: 42553-65-1
              Molecular Weight: 976.96
              Molecular Formula: C44H64O24
              Physical State : Solid
              Storage : Store at 4° C
              Melting Point : 186° C
              Boiling Point : ~1169.0° C at 760 mmHg (Predicted)
              Density : ~1.5 g/cm3`,
              style: "pre-line",
            },
          ],
        },
        {
          id: 2,
          th: "DEFINITION",
          td: [
            {
              id: 2,
              text: `Coloring principle of saffron (dried stigmas of flowers of Crocus sativus L). Crocins are natural drug carotenoid chemical compounds and glycosylated esters of crocetin. 	 	 
              `,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      thead: [
        {
          id: 1,
          tr: {
            id: 11,
            th: [
              {
                id: 111,
                text: "Physicochemical analysis",
                colSpan: 3,
                textStyle: "text-center",
              },
            ],
          },
        },
      ],
      tbody: [
        {
          id: 1,
          th: "Physical state",
          td: [
            {
              id: 1,
              text: `solid`,
            },
            {
              id: 2,
              text: `confirm`,
            },
          ],
        },
        {
          id: 2,
          th: "source",
          td: [
            {
              id: 1,
              text: `Saffron stigmas`,
            },
            {
              id: 2,
              text: `confirm`,
            },
          ],
        },
        {
          id: 3,
          th: "solubility",
          td: [
            {
              id: 1,
              text: `easily soluble in water and methanol`,
            },
            {
              id: 2,
              text: `confirm`,
            },
          ],
        },
        {
          id: 4,
          th: "Description",
          td: [
            {
              id: 1,
              text: `Deep red color crystals.
              Orang solution in water`,
              style:'pre-line'
            },
            {
              id: 2,
              text: `confirm`,
            },
          ],
        },
        {
          id: 5,
          th: "Spectrophotometry",
          td: [
            {
              id: 1,
              text: `Maximum absorption in water at 440 nm using a 1 cm quartz cell.`,
            },
            {
              id: 2,
              text: `confirm`,
            },
          ],
        },
        {
          id: 6,
          th: "Assay (HPLC)",
          td: [
            {
              id: 1,
              text: `Acceptable range 
              `,
            },
            {
              id: 2,
              text: `Result`,
            },
          ],
        },
        {
          id: 7,
          th: "",
          td: [
            {
              id: 1,
              text: `≥90`,
            },
            {
              id: 2,
              text: `99.93%`,
            },
          ],
        },
        {
          id: 8,
          th: "Loss on drying",
          td: [
            {
              id: 1,
              text: `≤ 7.5%`,
            },
            {
              id: 2,
              text: `4%`,
            },
          ],
        },
        {
          id: 9,
          th: "Total ash",
          td: [
            {
              id: 1,
              text: `≤ 5%`,
            },
            {
              id: 2,
              text: `4.4%`,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      thead: [
        {
          id: 1,
          tr: {
            id: 11,
            th: [
              {
                id: 111,
                text: "Microbial analysis",
                colSpan: 3,
                textStyle: "text-center",
              },
            ],
          },
        },
        {
          id: 2,
          tr: {
            id: 22,
            th: [
              {
                id: 222,
                text: "",
                colSpan: 1,
                textStyle: "text-left",
              },
              {
                id: 223,
                text: "Acceptable range",
                colSpan: 1,
                textStyle: "text-left",
              },
              {
                id: 224,
                text: "Result",
                colSpan: 1,
                textStyle: "text-left",
              },
            ],
          },
        },
      ],

      tbody: [
        {
          id: 1,
          th: "Total yeasts and molds count",
          td: [
            {
              id: 1,
              text: `≤ 100 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 2,
          th: "Total aerobic microbial count",
          td: [
            {
              id: 1,
              text: `≤ 10000 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 3,
          th: `Bile-tolerant
          bacteria
          grame
          negative`,
          td: [
            {
              id: 1,
              text: `≤ 100 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 4,
          th: `Total coliforms count`,
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 5,
          th: `E. coli`,
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 6,
          th: `Salmonella species`,
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 7,
          th: `Candida albicans`,
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 8,
          th: `Pseudomonas aeruginosa`,
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
        {
          id: 9,
          th: `Staphylococcus aureus`,
          td: [
            {
              id: 1,
              text: `0 cfu/g`,
            },
            {
              id: 2,
              text: `0 cfu/g`,
            },
          ],
        },
      ],
    },
  ],
};
