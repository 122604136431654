import TopImage from "../../assets/images/active-ingredient.webp";
import RightImage1 from "../../assets/images/active-ingredient-2.jpg";
import RightImage2 from "../../assets/images/active-ingredient-3.jpg";

export const data = {
  topImage: { src: TopImage, alt: "Other Active Ingredient from Saffron" },
  rightImage1: { src: RightImage1, alt: "Safranal, Saffron extract, Supplement saffron extract, Active ingredient of saffron, Saffron extract better mood, Pure saffron extract, High purity saffron extract, Saffron extract improve depression, Saffron extract supplement,Saffron innovation center" },
  rightImage2: { src: RightImage2, alt: "Anthocyanin from saffron petal, Saffron processing refinery, Supplement saffron extract, Active ingredient of saffron, Saffron extract better mood, Pure saffron extract, High purity saffron extract,Saffron extract improve depression,Saffron extract supplement, Saffron innovation center" },
  seo:"Safranal (Saffron extract) and Anthocyanin from saffron petal (Saffron processing refinery), Supplement saffron extract, Saffron extract better mood, Pure saffron extract, High purity saffron extract, Saffron extract improve depression,Saffron extract supplement,Saffron innovation center",
  keyword:["Safranal", "Saffron extract","Anthocyanin from saffron petal", "Saffron processing refinery","Supplement saffron extract", "Active ingredient of saffron","Saffron extract better mood","Pure saffron extract","High purity saffron extract","Saffron extract improve depression","Saffron extract supplement","Saffron innovation center"],
  cardInfo: {
    title: "Safranal",
    Subtitle: `Supplement saffron extract, Saffron extract producer`,
    text: [
      {
        id: 1,
        value: `Safranal, the main component of Crocus sativus essential oil, is thought to be the main cause
        of saffron unique odor. safranal is also known as a great antioxidant, a protective agent against
        indomethacin-induced gastric ulcers, a natural product for protection against PTZ-induced
        status epilepticus, a pharmacological tool that is able to inhibit the acquisition and expression
        of morphine-induced place preference (CPP) and a cytotoxic substance which is effective
        against specific cancer cells, in vitro .`,
      }
    ],
  },  cardInfo2: {
    title: "Anthocyanin from saffron petal ",
    Subtitle: `Active ingredient of saffron, Saffron processing refinery`,
    text: [
      {
        id: 1,
        value: `The violet color of the saffron petal is due to anthocyanin, while the main anthocyanidin
        aglycones are delphinidin and petunidin and different pharmacological properties of saffron
        petal such as anti-bacterial, anti-spasmodic, immunomodulatory, anti-tussive, anti-depressant,
        anti-nociceptive, hepato-protective, reno-protective, anti-hypertensive, anti-diabetic and anti-
        oxidant activity have been introduced .`,
      }
    ],
  },
};
