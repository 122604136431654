import Logo from "../../assets/images/logo.png";

export const data = {
  logo: {
    src: Logo,
    alt: "Agile Smart Health Technology",
  },
  menu: [
    {
      link: "/",
      name: "Home",
    },
    {
      link: "/about-us",
      name: "About Us",
    },
    {
      link: "/pharmaceutical",
      name: "Crocin in Pharmaceutical",
    },
    {
        link: "/food",
        name: "crocin in Food",
      },
      {
        link: "/cosmetic",
        name: "crocin in Cosmetic",
      },
      {
        link: "/active",
        name: "Active Ingredient from Saffron",
      },
      {
        link: "/native-herbal-medicine",
        name: "Native Herbal Medicine Of Iran",
      },
      {
        link: "/contact-us",
        name: "Contact Us",
      },
  ],
};
