import { Card, CardBody } from "reactstrap";

const CardWrapper = ({ title, Subtitle, text, noneDivider}) => {
  return (
    <Card>
      <CardBody>
        <h1 className="card-title text-center">{title} </h1>
        <h2 className="card-subtitle text-center">{Subtitle}</h2>
        <div className={noneDivider? "d-none" :"divider"}></div>
        {text.map(({ id, value }) => (
          <h3 className="card-text lead" key={id}>{value}</h3>
        ))}
      </CardBody>
    </Card>
  );
};
export default CardWrapper;
