import { Container, Row, Col } from "reactstrap";
import CardWrapper from "../../component/cardWrapper";
import RelatedWord from "../../component/relatedKeword";
import { data } from "./data";
const AboutUs = () => {
  return (
    <div>
      <img src={data.topImage.src} alt={data.topImage.alt} width="100%" />
      <Container>
        <Row>
          <Col>
            <CardWrapper
              Subtitle={data.cardInfo.Subtitle}
              title={data.cardInfo.title}
              text={data.cardInfo.text}
            />
            <h3 className="d-none">{data.seo}</h3>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="pl-6">
          <CardWrapper
              text={data.InfoLeft.text}
              noneDivider
            />
          <RelatedWord  currentPage={"/about-us"} keyword={data.keyword} />
          </Col>
          <Col md={6}>
            <img
              src={data.rightImage.src}
              alt={data.rightImage.alt}
              width="100%"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default AboutUs;
